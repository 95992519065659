import React, { useEffect } from 'react';
import './Dsdsceaimandscope.css';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsce/Accordion';
import Ddscearticletemplate from '../../downloads/DS-CE-Article_Template.doc';
import Ddscecopyrightform from '../../downloads/DS-CE-Copyright_Form.docx';
import Journaltitle from '../../pages/dsce/data.json';

const Dsdsceaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
           <NavLink smooth to="/ce" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
        <NavLink to="/ce/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
        <NavLink to="/ce/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/ce/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/ce/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/ce/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/ce/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Ddscearticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Ddscecopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
                <title>DS Journal of Reviews of Commerce and Economics - DS-CE</title>
                <link rel="canonical" href="https://dsjournals.com/ce"/>
                <meta name="title" content="DS Journal of Reviews of Commerce and Economics - DS-CE"/>
                <meta name="description" content="DSRCE Journal is a peer-reviewed publication that showcases innovative research across a variety of fields. Submit your paper and join our community."/>
                <meta name="keywords" content="economics in business, economics management, studies business, open access journals, peer reviewed journals, open economics, economics journal, journal of business and economics, open access publication, journal impact, research economics, journal of business economics."/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/ce"/>
                <meta property="og:title" content="DS Journal of Reviews of Commerce and Economics - DS-CE"/>
                <meta property="og:description" content="DSRCE Journal is a peer-reviewed publication that showcases innovative research across a variety of fields. Submit your paper and join our community."/>
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="DS Journal of Reviews of Commerce and Economics - DS-CE"/>
                <meta name="twitter:description" content="DSRCE Journal is a peer-reviewed publication that showcases innovative research across a variety of fields. Submit your paper and join our community."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>

            <section id="hero-no-slide-dsce" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* <h2 style={{fontSize:"25px",color:"black"}}>DS Reviews of Commerce and Economics ( DS-CE )</h2> */}
                                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-CE</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Reviews-of-Commerce-and-Economics.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2"> Dr. Cyriac Pattathil Joy,<br />
                                                                Assistant Professor,<br />
                                                                College of Business Co-Director - Master of Science in Sports Management (MSSPM) Program,<br />
                                                                Minot State University,<br />
                                                                North Dakota, USA.<br />
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">XXXX-XXXX</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Commerce and Economics</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                {/* <td className="col-md-8 px-2">submit2dsrce@gmail.com</td> */}
                                                                <td className="col-md-8 px-2">economics@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>DS Reviews of Commerce and Economics (DS - CE) is an international, peer-reviewed, open-access journal that 
                                encourages and supports the publication of high-quality review articles, and perspectives summarizing and evaluating the stock of theoretical, empirical, and applied 
                                knowledge that explores important dimensions of the global economic system, finance and commerce. The aim of the journal is to provide a platform for scientists all 
                                over the world to promote, share and disseminate their research innovations in comparison to already reported state-of-the-art research literature on economics, and 
                                commerce. In addition to theoretical and empirical reviews, policy-related works, excellent state-of-the-art and conceptual contributions in the form of Case Reports,
                                Addendums, Historical Notes, short notes, Narration/Opinions, and Book Reviews will also be considered.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                DS-CE is also open for publications in other fields of economics and commerce that are of general interest to a broad readership, promoting the exchange of ideas 
                                between science and practice. There is no restriction on the length of the papers, and publication is immediate upon acceptance, following a fast and thorough review 
                                process. The journal’s aim is to encourage scientists to publish their reviews in as much detail as possible. Articles must aim to be thought-provoking and combine 
                                rigour with readability so as to be of interest to both researchers as well as policymakers.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Papers are in demand, but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>



                                        <p><i className="bi bi-chevron-double-right"></i>Accountancy and Accounting  </p>
<p><i className="bi bi-chevron-double-right"></i>Bayesian Econometrics</p>
<p><i className="bi bi-chevron-double-right"></i>Bayesian Nonparametrics</p>
<p><i className="bi bi-chevron-double-right"></i>Biased Estimation</p>
<p><i className="bi bi-chevron-double-right"></i>Business studies</p>
<p><i className="bi bi-chevron-double-right"></i>Commodities and food security</p>
<p><i className="bi bi-chevron-double-right"></i>Commodities economics and finance</p>
<p><i className="bi bi-chevron-double-right"></i>Commodities market</p>
<p><i className="bi bi-chevron-double-right"></i>Commodities policy</p>
<p><i className="bi bi-chevron-double-right"></i>Commodities supercycles</p>
<p><i className="bi bi-chevron-double-right"></i>Communication Management</p>
<p><i className="bi bi-chevron-double-right"></i>Consumer Finance</p>
<p><i className="bi bi-chevron-double-right"></i>Corporate finance</p>
<p><i className="bi bi-chevron-double-right"></i>Corporate Governance</p>
<p><i className="bi bi-chevron-double-right"></i>Corporate Social Responsibility</p>
<p><i className="bi bi-chevron-double-right"></i>Data Analysis and Decision Making</p>
<p><i className="bi bi-chevron-double-right"></i>Econometric Models</p>
<p><i className="bi bi-chevron-double-right"></i>Econometric Theory</p>
<p><i className="bi bi-chevron-double-right"></i>Economic development</p>
<p><i className="bi bi-chevron-double-right"></i>Education Management</p>
<p><i className="bi bi-chevron-double-right"></i>Entrepreneurship and Innovation</p>
<p><i className="bi bi-chevron-double-right"></i>Environmental Management</p>
<p><i className="bi bi-chevron-double-right"></i>Estimation Frameworks</p>
<p><i className="bi bi-chevron-double-right"></i>Ethics in Management</p>
<p><i className="bi bi-chevron-double-right"></i>Financial Econometrics</p>
<p><i className="bi bi-chevron-double-right"></i>Financial markets and institutions</p>
<p><i className="bi bi-chevron-double-right"></i>General Management</p>
<p><i className="bi bi-chevron-double-right"></i>Growth and natural resources</p>
<p><i className="bi bi-chevron-double-right"></i>Health economics</p>
<p><i className="bi bi-chevron-double-right"></i>Hospital Management</p>
<p><i className="bi bi-chevron-double-right"></i>Hospitality Management</p>


                                        </div>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Hotel Management</p>
<p><i className="bi bi-chevron-double-right"></i>Human Resource Management</p>
<p><i className="bi bi-chevron-double-right"></i>Insurance</p>
<p><i className="bi bi-chevron-double-right"></i>Intellectual Property Rights</p>
<p><i className="bi bi-chevron-double-right"></i>International Business</p>
<p><i className="bi bi-chevron-double-right"></i>International trade regional economics</p>
<p><i className="bi bi-chevron-double-right"></i>Inventory Management</p>
<p><i className="bi bi-chevron-double-right"></i>investments</p>
<p><i className="bi bi-chevron-double-right"></i>Knowledge Management</p>
<p><i className="bi bi-chevron-double-right"></i>Labour and education economics</p>
<p><i className="bi bi-chevron-double-right"></i>Leisure Management</p>
<p><i className="bi bi-chevron-double-right"></i>Lifecycle Management</p>
<p><i className="bi bi-chevron-double-right"></i>Limited Dependent Variables</p>
<p><i className="bi bi-chevron-double-right"></i>Macroeconomic economic theory and policy</p>
<p><i className="bi bi-chevron-double-right"></i>Marketing </p>
<p><i className="bi bi-chevron-double-right"></i>Microeconometrics</p>
<p><i className="bi bi-chevron-double-right"></i>Models for Count Data</p>
<p><i className="bi bi-chevron-double-right"></i>Monetary and financial economics</p>
<p><i className="bi bi-chevron-double-right"></i>Negotiations and Competitive Decision Making</p>
<p><i className="bi bi-chevron-double-right"></i>Organizational Behavior</p>
<p><i className="bi bi-chevron-double-right"></i>Organizational Management</p>
<p><i className="bi bi-chevron-double-right"></i>Panel Data</p>
<p><i className="bi bi-chevron-double-right"></i>Public Management</p>
<p><i className="bi bi-chevron-double-right"></i>Research in real estate</p>
<p><i className="bi bi-chevron-double-right"></i>Rural Management</p>
<p><i className="bi bi-chevron-double-right"></i>Spiritual Management</p>
<p><i className="bi bi-chevron-double-right"></i>Strategic Decision Making</p>
<p><i className="bi bi-chevron-double-right"></i>Technology and Operations Management</p>
<p><i className="bi bi-chevron-double-right"></i>Tourism Management</p>
<p><i className="bi bi-chevron-double-right"></i>Waste Management</p>




                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdsceaimandscope;
