import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsce/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddscearticletemplate from '../../downloads/DS-CE-Article_Template.doc';
import Ddscecopyrightform from '../../downloads/DS-CE-Copyright_Form.docx';
import Journaltitle from '../../pages/dsce/data.json';

const Dsdscetopics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
          <HashLink smooth to="/ce" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
        <NavLink to="/ce/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
        <NavLink to="/ce/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/ce/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/ce/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/ce/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/ce/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Ddscearticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Ddscecopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>

        </div>
    },
    ];

    return (
        <>
            <Helmet>
            <title>Topics Covered - DS-CE Journal</title>
            <link rel="canonical" href="https://dsjournals.com/ce/topics"/>
            <meta name="title" content="Topics Covered - DS-CE Journal"/>
            <meta name="description" content="Discover the wide range of topics covered by DS-CE Journal. From economics and commerce, explore the latest research and insights in your field."/>
            <meta name="keywords" content="Bayesian Econometrics, Human Resource Management, Inventory Management, journal of Labour and education economics, Lifecycle Management, Micro econometrics, Econometric Models."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/ce/topics"/>
            <meta property="og:title" content="Topics Covered - DS-CE Journal"/>
            <meta property="og:description" content="Discover the wide range of topics covered by DS-CE Journal. economics and commerce, explore the latest research and insights in your field."/>
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Topics Covered - DS-CE Journal"/>
            <meta name="twitter:description" content="Discover the wide range of topics covered by DS-CE Journal. From economics and commerce, explore the latest research and insights in your field."/>
            <meta name="twitter:site" content="@DreamScience4"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
            <meta name="robots" content="index, follow"/>
            </Helmet>
            <section id="hero-no-slide-dsce" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* <h2 style={{color:"black"}}>Topics</h2> */}
                                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li> <NavLink to="/ce">DS-CE</NavLink></li>
                            <li>Topics</li>
                        </ol>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">

                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Topics</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    DS-CE is also open for publications in other fields of economics and commerce that are of general interest to a broad readership, promoting the exchange of ideas between science and practice.
                                    There is no restriction on the length of the papers, and publication is immediate upon acceptance, following a fast and thorough review process. The journal’s aim is to encourage scientists
                                    to publish their reviews in as much detail as possible. Articles must aim to be thought-provoking and combine rigour with readability so as to be of interest to both researchers as well as
                                    policymakers.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>The papers are demanded, but not limited to, the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                                <li>Accountancy and Accounting  </li>
                                                <li>Bayesian Econometrics</li>
                                                <li>Bayesian Nonparametrics</li>
                                                <li>Biased Estimation</li>
                                                <li>Business studies</li>
                                                <li>Commodities and food security</li>
                                                <li>Commodities economics and finance</li>
                                                <li>Commodities market</li>
                                                <li>Commodities policy</li>
                                                <li>Commodities supercycles</li>
                                                <li>Communication Management</li>
                                                <li>Consumer Finance</li>
                                                <li>Corporate finance</li>
                                                <li>Corporate Governance</li>
                                                <li>Corporate Social Responsibility</li>
                                                <li>Data Analysis and Decision Making</li>
                                                <li>Econometric Models</li>
                                                <li>Econometric Theory</li>
                                                <li>Economic development</li>
                                                <li>Education Management</li>
                                                <li>Entrepreneurship and Innovation</li>
                                                <li>Environmental Management</li>
                                                <li>Estimation Frameworks</li>
                                                <li>Ethics in Management</li>
                                                <li>Financial Econometrics</li>
                                                <li>Financial markets and institutions</li>
                                                <li>General Management</li>
                                                <li>Growth and natural resources</li>
                                                <li>Health economics</li>
                                                <li>Hospital Management</li>
                                                <li>Hospitality Management</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                                <li>Hotel Management</li>
                                                <li>Human Resource Management</li>
                                                <li>Insurance</li>
                                                <li>Intellectual Property Rights</li>
                                                <li>International Business</li>
                                                <li>International trade regional economics</li>
                                                <li>Inventory Management</li>
                                                <li>investments</li>
                                                <li>Knowledge Management</li>
                                                <li>Labour and education economics</li>
                                                <li>Leisure Management</li>
                                                <li>Lifecycle Management</li>
                                                <li>Limited Dependent Variables</li>
                                                <li>Macroeconomic economic theory and policy</li>
                                                <li>Marketing </li>
                                                <li>Microeconometrics</li>
                                                <li>Models for Count Data</li>
                                                <li>Monetary and financial economics</li>
                                                <li>Negotiations and Competitive Decision Making</li>
                                                <li>Organizational Behavior</li>
                                                <li>Organizational Management</li>
                                                <li>Panel Data</li>
                                                <li>Public Management</li>
                                                <li>Research in real estate</li>
                                                <li>Rural Management</li>
                                                <li>Spiritual Management</li>
                                                <li>Strategic Decision Making</li>
                                                <li>Technology and Operations Management</li>
                                                <li>Tourism Management</li>
                                                <li>Waste Management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdscetopics;