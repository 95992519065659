import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Posts from '../../components/Dsrls/Articlelist/Posts/Posts';
import { getjournalPosts } from '../../actions/journal/dsrls/posts';
// import { getjournalPosts } from '../../actions/journal/dlan/posts';
import Accordion from '../../components/Dsrls/Accordion';
import Ddsrlsarticletemplate from '../../downloads/DS-RLS-Article_Template.doc';
import Ddsrlscopyrightform from '../../downloads/DS-RLS-Copyright_Form.docx';
import Journaltitle from '../../pages/dsrls/data.json';


const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [refresh,setReferesh] = useState(false);

  const jouname = "RLS";
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));


  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    if (cpost) setPostData(cpost);
    console.log("currentissue",cpost);
  }, [cpost]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
       <HashLink smooth to="/rls" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/rls/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/rls/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/rls/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/rls/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/rls/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/rls/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddsrlsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddsrlscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
    <title>Current Issue - DS Reviews of Research in Life Sciences (DS-RLS)</title>
<link rel="canonical" href="https://dsjournals.com/rls/current-issue"/>
<meta name="viewport" content="width=device-width, initial-scale=1"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
<meta name="title" content="Current Issue - DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta name="description" content="DS-RLS - The current issue features articles on a range of topics in the field of research in life sciences. Stay updated with the latest research in the field."/>
<meta name="keywords" content="Current Issue in dsrls journals, recent research paper in life science journals, ds journal, journal of life science, life science international journal, life science journal ugc care, life science journals in india, life science journals in ugc care list, life science journals ugc approved, life science peer reviewed journal, research review international journal of life science, ugc care journal life science, international journal for life science research."/>
<meta property="og:type" content="Website"/>
<meta property="og:site_name" content="Dream Science"/>
<meta property="og:url" content="https://dsjournals.com/rls/current-issue"/>
<meta property="og:title" content="Current Issue - DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta property="og:description" content="DS-RLS - The current issue features articles on a range of topics in the field of research in life sciences. Stay updated with the latest research in the field."/>
<meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Current Issue - DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta name="twitter:description" content="DS-RLS - The current issue features articles on a range of topics in the field of research in life sciences. Stay updated with the latest research in the field."/>
<meta name="twitter:site" content="@DreamScience4"/>
<meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
<meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="robots" content="index, follow"/>


    </Helmet>
     <>
     <section id="hero-no-slide-dsrls" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           <div className="col-xl-8">
             {/* <h2>Current Issue</h2> */}
             {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
           
           </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       {/* <h2>Current Issues</h2> */}
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
        <li><Link to="/rls">DS-RLS</Link></li>
         <li>Current Issue</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div> 
         </div>
         <div class="col-lg-9">
               <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 {/* <h5 style={{alignItems:'center'}}>Volume 1 Issue 1,  July – September 2022 </h5> */}
                 {/* <h5 style={{alignItems:'center'}}>Volume 1 Issue 1</h5> */}
                 <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5>
                 </div>
             
             </div>
             <div className="row">
              <div className='col-lg-12 mt-3'>
              <Posts setCurrentId={setCurrentId} />  
              </div>
               
             </div>
         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
</>
  )
}

export default Currentissue